.heroEVENT-img {
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.heroEVENT-img:before {
    content:'';
    background: url('../assets/EVE.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
}

.heroEVENT-img .headingEVENT {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroEVENT-img h1 {
    font-size: 2.4rem;
}

.heroEVENT-img p {
    font-size: 1.4rem;
    padding-left: 20px;
    padding-right: 20px;
}

@media screen and (max-width:640px) {
    .heroEVENT-img h1 {
        font-size: 2rem;
    }
}