/* .navbar {
    width: 100%;
    height: 90px;
    background-color: transparent;
} */
.navbar {
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;

}
.navbar .container {
    max-width: 1140px;
    height: 100%;
    margin: auto;
    display: flex;
    padding-right: 15px;
    justify-content: space-between;
    align-items: center;
}

.navbar-bg {
    background-color: rgba(0,0,0, .85);
    transition: .5s;
}


.navbar .nav {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar li {
    padding: 1rem;
}

.hamburger {
    display: none;
}

.navbar .icon {
    color: #f4eeff;
    font-size: 2rem;
}

.navbar h1,a {
    color: #f4eeff;
}

@media screen and (max-width:940px) {
    .hamburger {
        display: block;
    }

    .nav {
        position: absolute;
        flex-direction: column;
        justify-content: start!important;
        top: 90px;
        left: -100%;
        width: 100%;
        height: 100vh;
        background-color: #000;
        transition: 0.3s;
    }

    .nav.active {
        left: 0;
    }

    .nav a {
        font-size: 1.4rem;
    }

}