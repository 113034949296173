.developer {
    background-color: #000;
    height: 80vh;
    padding-top: 7%;
    }
    
    .developer .container {
        max-width: 1140px;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .developer .left {
        background-color: #000;
        color: #f8f8f8;
        margin: auto;
        padding: 1rem;
    }
    
    .developer .right {
        background-color: #000;
    }
    
    .img-container {
        text-align: center;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        /* background-color: #fff; */

    }
    
    .developer img {
        width: 250px;
        box-shadow: 0px 0px 12px var(--primary-blue);
    }
    
    @media screen and (max-width:940px) {
        .developer {
            background-color: #000;
            height: 100vh;
            padding-top: 15%;
            /* padding-bottom: 20%; */
            }
        .developer .container {
            flex-direction: column;
        }
    
        .developer .right {
            margin: 3rem 0;
        }
    }