.footer1 {
    width: 100%;
    /* background-color: #000; */
    background: url('../../assets/seb.jpg') no-repeat center center/cover;
    color: #f8f8f8;
    padding: 4rem 0;
    text-align: center;
}

.footer1 .container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(5, 1fr);
}

.footer1 h4 {
    display: inline-block;
    border-bottom: 3px solid var(--primary-blue);
    padding-bottom: .2rem;
}

.footer1 .col p {
    margin: 1rem 0;
}

@media screen and (max-width: 940px) {
    .footer1 .container {
        grid-template-columns: 1fr 1fr;
    }

    .footer1 h4 {
        margin-top: 2rem;
    }
}








