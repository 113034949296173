.a {
    width: 100%;
    text-align: center;
    align-items: center;
    align-self: center;
    /* padding: 3rem 1rem; */
    /* background-color: #fff; */
    background: url('../../assets/sabe.jpg') no-repeat center center/cover;
    /* background-image: linear-gradient(45deg, #fff 0%, #808080  100%); */
    padding: 20px;
}
.ab {
    width: 100%;
    text-align: center;
    align-items: center;
    align-self: center;
    /* padding: 3rem 1rem; */
    /* background-color: #fff; */
    /* background: url('../../assets/sabe.jpg') no-repeat center center/cover; */
    /* background-image: linear-gradient(45deg, #fff 0%, #808080  100%); */
    padding-top: 50px;
}

.a h2 {
    margin: 1rem 0;
    color: #fff;
}

.a .container {
    align-items: center;
    align-self: center;
    max-width: 1140px;
    margin: auto;
}

.a .containe > p {
    margin: 1rem 0 3rem 0;
    font-size: 1.2rem;
    color: #fff;
}

.a .car-containe {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 1rem;
    align-items: center;
    align-self: center;
    column-gap: 1rem;
    row-gap: 1rem;
}

.a .car {
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    width: 260px;
    padding: 3rem 2rem;
    border: 1px solid #e7e8ea;
    text-align: left;
    border-radius: 24px;
    margin: auto;
    background: url('../../assets/100.jpg') no-repeat center center/cover;
    /* background-color: #333 */
}
.a .car:hover {
    transform: scale(1.05);
    transition: 0.4s ease-in-out;
}


.a .car h3 {
    margin: 1rem 0;
    color: #f8f8f8;
}

.a .car p {
    color: #ccc;
}

.a .icon-containe {
    background-color: var(--primary-blue);
    border-radius: 50px;
    display: inline-flex;
    padding: 1rem;
}

.a .icon {
    font-size: 2rem;
    color: #333;
}
.video99 {
    display: block;
    padding-top: 50px;
    /* position: absolute; */
    /* z-index: -100px; */
}

.a .btn {
    display: inline-block;
    margin-top: 1rem;
}

@media screen and (max-width:940px) {
    .a .car-containe {
        grid-template-columns: repeat(2, 1fr);
    }
    .a {
        width: 100%;
        text-align: center;
        padding: 0px;
        padding-top: 1px;
        padding-bottom: 10px;
        /* padding: 3rem 1rem; */
        /* background-color: #000; */
        margin-top: 50px;
    }
    .videos {
        display: none;
        padding-top: 50px;
  }
}
@media screen and (max-width:600px) {
    .a .car-containe {
        grid-template-columns: 1fr;
    }
    .videos {
        display: none;
        padding-top: 50px;
  }
}













