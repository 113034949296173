.hero {
    /* height: 90vh; */
    height: 100%;
    width: 100%;
    background-color: black;
    top: 190px;
    object-fit: contain;
    color: #f4eeff;
}
#video {
    position: fixed;
    width: 100vh;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.hero .hero-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: absolute;
    right: 0px;
    left: 0px;
    top: 0%;
    bottom: 0%;
    padding-top: 0px;
    text-align: center;
}

.hero p {
    font-size: 1.4rem;
}

.hero .btn {
    margin: 1rem;
}

.bottom-text h2 {
    font-size: 1.6rem;
    text-align: center;
}
/* 
@keyframes slideIn {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .animate-slide-in {
    animation: slideIn 1s ease-in-out forwards;
  }
   */









