@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@100;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-blue: #00d8ff;
  --primary-purple: #ff3250;
  /* --primary-purple: #6a00ff; */
}

h1,h4,p,a {
  color: #fff;
}
.blue {
  color: var(--primary-blue);
}
body {
  margin: 0;
  font-family: 'Kanit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}
ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .btn {
  padding: 12px 32px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
} */

.btn {
  padding: 10px 28px;
  font-size: 1.2rem;
  background-image: linear-gradient(45deg, var(--primary-purple) 0%, var(--primary-blue) 100%);
  color: #000;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'Kamit', sans-serif;
}

.btn-outline {
  background: transparent;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
}

.btn-light {
  padding: 10px 28px;
  font-size: 1.2rem;
  background-image: linear-gradient(45deg, var(--primary-purple) 0%, var(--primary-blue) 100%);
  color: #000;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.btn:hover {
  background: rgba(255,255,255, .2);
}